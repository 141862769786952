import { Container, Spinner } from '@chakra-ui/react'
import { useContentPagesQuery, useContentQuery } from '@stocker/codegen/pimcore'
import { Breadcrumbs, HeroSimple, Seo } from '@stocker/ui-components/design-system'
import { getServerData } from '@stocker/ui-components/helpers'
import { QueryClient, dehydrate } from '@tanstack/react-query'
import type { GetStaticPaths, GetStaticPathsResult, GetStaticProps, InferGetStaticPropsType, NextPage } from 'next'
import { useRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'
import { handlePrefetchError } from '@stocker/codegen/helpers'
import { getContentBlocks, prepareContentResponse } from '@stocker/ui-components/custom'

interface IStaticParams extends ParsedUrlQuery {
  slug?: string[]
}

interface IStaticPropsParams {
  preview: boolean
}

export const getStaticPaths: GetStaticPaths<IStaticParams> = async ({ locales = ['de', 'en'] }) => {
  const paths = await Promise.all(locales.map(async (locale): Promise<GetStaticPathsResult<IStaticParams>['paths']> => {
    const contentPages = await useContentPagesQuery.fetcher({ defaultLanguage: locale })()

    const slugs = contentPages.getContentPageListing?.edges?.map(edge => edge?.node?.absolutePath)
      .filter((path): path is string => typeof path === 'string') ??
        []

    return slugs.map((slug) => ({
      params: {
        slug: slug.split('/').slice(1),
      },
      locale,
    }))
  }))

  return {
    paths: paths.flat(),
    fallback: 'blocking',
  }
}

interface IStaticPropsParams {
  preview: boolean
  revalidateTime: number
}
export const getStaticProps: GetStaticProps<IStaticPropsParams, IStaticParams> = async ({ params, preview, locale = 'de' }) => {
  const queryClient = preview ? new QueryClient({ defaultOptions: { queries: { cacheTime: 0 } } }) : new QueryClient()
  const variables = { lang: locale, path: `/${(params?.slug ?? []).join('/')}` }

  if (preview) {
    queryClient.invalidateQueries(useContentQuery.getKey(variables))
  }

  try {
    const data = await useContentQuery.fetcher(variables)()
    queryClient.setQueryData(useContentQuery.getKey(variables), data)
  } catch (error) {
    return handlePrefetchError<IStaticPropsParams & IStaticParams>(error)
  }
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      preview: !!preview,
      revalidateTime: Date.now(),
      ...(await getServerData()), // Data needed for _app.tsx
    },
    revalidate: 30,
  }
}

const ContentPage: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  const { query: params, locale } = useRouter()
  const { data, isLoading, error } = useContentQuery({ lang: locale ?? 'de', path: `/${(params.slug as string[] | undefined)?.join('/') ?? ''}` }, {
    staleTime: 1000 * 60 * 10,
  })

  if (!data && isLoading) return <Spinner/>
  // TODO: [AC-123] Error handling
  if (error || !data) return null
  const { seo, header, breadcrumbs } = prepareContentResponse(data)

  const firstElement = data.getContentPageBySlugOrPath?.content?.elements?.at(0)?.__typename

  return (
    <>
      <Seo
        title={seo.title ? seo.title : 'Stocker'}
        description={seo.description}
        canonicals={seo.canonicals}
      />
      {header?.image && (
        <HeroSimple
          image={{
            fullpath: `${header.image.fullpath}?width=1900`,
            blurDataUrl: header.image.blurDataUrl,
          }}
          headline={header.title}
          colorAccent={header.titleColor}
          mb={0}
        />
      )}
      {breadcrumbs.data.length > 1 && (
        <Container maxW="container.page" pt={6} pb={12}><Breadcrumbs data={breadcrumbs.data}/></Container>
      )}
      {firstElement === 'fieldcollection_HeroSwiper'
        ? (
          <>
            {getContentBlocks(data)}
          </>
          )
        : (
          <Container maxW="container.page" pt={(breadcrumbs.data.length > 1) ? 0 : 6} pb={12}>
            {getContentBlocks(data)}
          </Container>
          )}
    </>
  )
}

export default ContentPage
